import React from "react"
import SEO from "../components/seo"
import '../../src/styles/app.scss';
import { Link } from 'gatsby';

import Fofimg from '../../src/images/404Error.png'
import Logo from '../../src/images/logo.png'


class FourofourPage extends React.Component {
  render() {

    return (
      <>


        <SEO title="404: Not found" />
        <div className="logo-box four-box">
          <Link className="logo-link" to='/'>
            <div className="four-page">
              <img className="logo" alt="Logo" src={Logo} />
            </div>
          </Link>


        </div>
        <div className="fof"><img src={Fofimg} alt="Not Found" />
          <span>
            Oops! The page you have been looking for has been either moved, deleted or does not exist.
          </span>
          <div>
            <button className="btnGoback">Go to Homepage</button>
          </div>
        </div>

      </>
    )
  }
}

export default FourofourPage
